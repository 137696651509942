/* Sitewide CSS */
.st0{fill:#B99458;}

#root, body, html{
  /* font-family: Camphor, Open Sans, Segoe UI, sans-serif; */
  font-family: 'Roboto', sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	line-height: 1.6;
  font-size: 14 px;
	max-height: 100vh;
	background-color: #181818;
	color: #858585;
}

/* Typography */

h1, .title{
	color: #B99458;
  font-size: 3.375em;
  line-height: 1.25;
  font-weight: 300;
  font-family: 'Oswald', sans-serif;
  /* text-transform: uppercase; */
}

h2, .heading{
  font-size:2.25em;
  font-weight: 300;
  letter-spacing: 0.0625em;
  color: #B99458;
  text-transform: uppercase;
  font-family: 'Raleway', sans-serif;
}

h3, .subheading{
  text-transform: uppercase;
  letter-spacing: 0.0425em;
  font-family: 'Raleway', sans-serif;
  font-size:1.5em;
}

p, .body-text{
  font-size:1em;
  font-family: 'Roboto', sans-serif !important;
}

.code{
  font-variant: small-caps;
  font-family: 'Ubuntu Mono', monospace;
}

.iam{
	color:#7b8e94;
}

.highlight{
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
}

.header ul li a{
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.0625em;
  text-decoration-line: none;
  color: #94ACB0;
  display: none;
  text-align:center;
  margin: 1rem 2rem 1rem 2rem;
}

/* Links */

a{
	color:#94ACB0;
}

a:hover{
	color:#7b8e94;
}

/* Body */

.main{
	float:left;
	width:100%;
	margin-top:5.5em;
}

body {
  margin: 0px;
  padding: 0;
}

.content{
	margin: 0 5% 0 5%;
}

/* Home Page CSS */

.home{
}

.intro{
  text-align:center;
}

.portrait{
  display:none;
}

.social-links i{
  margin:1em 1em 1em 1em;
  font-size:2em;
}


.btn{
  background-color: #181818; /* Green */
  border: solid;
  border-color: #94ACB0;
  color: #BF9551;
  padding: 0.25em 5.5em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

@media screen and (min-width: 640px){
  .social-links i{
    margin:1em 1em 1em 0em;
    font-size:2em;
  }
  .intro{
    margin:2em 0 6em 6em;
    text-align:left;
    width:30%;
    float:left;
  }

  .portrait{
    display: block;
    margin:8em 10% 0 10%;
    width:30%;
    float:right;
  }
}

/* About CSS */

.about{
  margin:20px 5px 20px 5px;
  padding:2%;
  /* background-color:rgb(43, 42, 42); */
}

.personal{
  margin: auto;
  max-width: 30em;
  text-align: justify;
}

.personal .title{
  text-align: center;
  margin-bottom:1em;
}

.experience h3.title{
  text-align: center;
  margin-top:3em;
  margin-bottom:1em;
}

.container {
  max-width: 60em;
  margin: auto;
}

.col-md-8{
  width: 100%;
  display:block;
}

.col-md-4{
  width: 100%;
}

.job-img{
  max-width: 70%;
  margin: 2.5em;
}

.img-reduction{
  max-width:60%;
}

/* Projects CSS */
.section{width:100%; margin:2%; display:inline-block;}
.section-description{width:50%; float:left;}
.section-images{height:100%; width:50%; display:block; float:right; margin: 2em 0 2em 0;}
.section-img{margin-left:auto; margin-right:auto; max-width:80%; display:block;}
.section-img2{margin-left:auto; margin-right:auto; max-width:60%; display:block;}

.project-item{
  margin-top:2em;
}

ul.projects-grid{
	margin: 0 auto;
  max-width: 1200px;
  padding: 0 1rem;
	list-style: none;
}

li.project{
	display: block;
	text-align: center;
	overflow:hidden;
	margin:1em 0 1em 0;
  cursor: pointer;
}

@media screen and (min-width: 640px) {
  ul.projects-grid{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  li.project{
    width: 50%;
		margin: 0;
  }
}

@media screen and (min-width: 1000px) {
  li.project {
    width: calc(100% / 3);
		margin:0;
  }
}

li.project div.title{
	width:100%;
  height:100%;
	font-size: 1.1em;
	text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;

	background: rgb(25, 25, 25); /* Fall-back for browsers that don't
                                    support rgba */
  background: rgba(25, 25, 25, .8);
	transition: 1s ease;
	-webkit-transition: all 1s ease;
}

li.project>div>a>div.title:hover, li.project>div>a:focus>div.title{
	background: rgba(25, 25, 25, 0.5);
	transition: 1s ease;
	-webkit-transition: all 1s ease;
}

li.project img{
	position: relative;
	max-width: 100%;
	display: flex;
  justify-content: center;
}

.video-responsive{
    margin: 3em 0 3em 0;
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}

.video-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

/* Project Description Styling */

.goBack{
  font-size:2em;
  /* float: left;
  max-width:5%;
  position: fixed; */
}

.goBack a{
	text-decoration-line:none;
}

.animated-project-wrapper{
  /* max-width:95%;
  float:right;
  margin:auto; */
}

.project-description{
  margin: auto;
  max-width: 45em;
}

.project-details{
  margin: auto;
  max-width: 45em;
}

.project-content{
  margin: auto;
  max-width: 50em;
}

.project-content>h2{
  text-align: center;
}

.project-item h1{
  text-align: center;
}

.no-list-style{
  list-style: none;
}

.no-bottom-margin{
  margin-bottom:0;
}

.no-top-margin{
  margin-top:0;
}

.indent{
  display: block;
  width:79%
}

.img-float-right{
  float: right; display: block; margin: 2% 2%;
}

.img-center{
  display: block;
  margin: 0 auto;
  width: 100%;
}

/* Graphics Projects */

  b{
    border-bottom:1px solid;
  }

  .section{
  width:100%;
  margin:2%;
  display:inline-block;
  border-bottom:1px solid;
  padding-bottom:5%
  }
/* borrowing slideshow css from https://themarklee.com/2013/12/26/simple-diy-responsive-slideshow-made-html5-css3-javascript/
*/
  .diy-slideshow{
  position: relative;
  display: block;
  overflow: hidden;
  }
  figure{
  position: absolute;
  opacity: 0;
  transition: 1s opacity;
  }
  figcaption{
  position: absolute;
  font-family: sans-serif;
  font-size: .8em;
  bottom: 2em;
  right: 4em;
  padding: .25em;
  color: #fff;
  background: rgba(0,0,0, .25);
  border-radius: 2px;
  }
  figcaption a{
  color: #fff;
  }
  figure.show{
  opacity: 1;
  position: static;
  transition: 1s opacity;
  }
  .next, .prev{
  color: #fff;
  position: absolute;
  background: rgba(0,0,0, .6);
  top: 50%;
  z-index: 1;
  font-size: 2em;
  margin-top: -.75em;
  opacity: .3;
  user-select: none;
  }
  .next:hover, .prev:hover{
  cursor: pointer;
  opacity: 1;
  }
  .next{
  right: 0;
  padding: 10px 5px 15px 10px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  }
  .prev{
  left: 0;
  padding: 10px 10px 15px 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  }
  figure{
  position: absolute;
  opacity: 0;
  transition: 1s opacity;
  }
  figure.show{
  opacity: 1;
  position: static;
  transition: 1s opacity;
  }

/* Header CSS */

.header{
  position: fixed;
  z-index:1;
  background-color: #181818;
  opacity:0.8;
  width: 100%;
}
.header img{
  max-height:2em;
  max-width:2em;
  margin:1.75em;
  float:left;
}
.header ul{
  list-style: none;
  display: block;
  float: left;
  padding:0;
  width:calc(100% - 1.75rem - 2rem - 1.75rem - 1.75rem - 1.5rem - 1.75rem);
}
.header ul li{
  float: right;
  text-align:center;
  width:100%;
  transition-property: display;
}

.header ul.is-active li a {
  display:block;
  opacity: 1;
  -webkit-animation: showNav 250ms ease-in-out both;
  animation: showNav 250ms ease-in-out both;
}

@-webkit-keyframes showNav {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes showNav {
    from { opacity: 0; }
      to { opacity: 1; }
}

.header ul li a:hover{
  color:#7b8e94;
}

.header ul li a.active{
    color: #B99458 !important;
    visibility: visible;
    display: block;
}

.c-hamburger {
  display: block;
  position: relative;
  overflow: hidden;
  float:right;
  margin: 1.75rem;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
}

.c-hamburger:focus {
  outline: none;
}

.c-hamburger span {
  display: block;
  position: absolute;
  top: 0.75rem;
  left: 0.05rem;
  right: 0.05rem;
  height: 0.2rem;
  background: #7b8e94;
}

.c-hamburger span::before,
.c-hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background-color: #7b8e94;
  content: "";
}

.c-hamburger span::before {
  top: -0.5rem;
}

.c-hamburger span::after {
  bottom: -0.5rem;
}

.c-hamburger--htx {
  background-color: #181818;
}

.c-hamburger--htx span {
  transition: background 0s 0.3s;
}
.c-hamburger--htx span,
.c-hamburger--htx span::before,
.c-hamburger--htx span::after {
  transition-duration: 0.3s, 0.3s, 0.3s;
  transition-delay: 0s, 0.3s, 0s;
}

.c-hamburger--htx span::before {
  transition-property: top, transform;
}

.c-hamburger--htx span::after {
  transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.c-hamburger--htx.is-active span {
  background: none;
}

.c-hamburger--htx.is-active span::before {
  top: 0;
  transform: rotate(45deg);
}

.c-hamburger--htx.is-active span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.header ul.is-active li{
  float:right;
}

@media screen and (min-width: 640px) {
  .header ul{
    float:right;
    width:auto
  }
  .header ul li{
    text-align:left;
    width: auto;
    float:left;
  }
  .header ul li a{
    display: block;
    visibility: visible;
    opacity: 1;
    transition: none;
    transform: none;
  }
  .c-hamburger{
    display:none;
  }
}

/* Spacing for large screens */

@media screen and (min-width: 640px) {
  .container{
    display: flex;
  }
  .col-md-8{
    width: 66%;
    height:100%;
    align-items: stretch;
  }

  .col-md-4{
    width: 33%;
    height:100%;
    float: left;
    align-items: stretch;
  }
}
